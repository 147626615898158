<template>
  <div class="userRegister">
    <h2>用户注册</h2>
    <el-form ref="form" :model="form" :rules="rules">
      <div v-show="progress == '1'" class="first">
        <el-form-item>
          <el-checkbox v-model="checked" class="checkBox">
            已阅读并同意<span
              style="color: #409eff"
              @click="$router.push('/Article')"
            >
              使用条款</span
            >
          </el-checkbox>
        </el-form-item>
        <el-form-item prop="phone">
          <el-input
            v-model.trim.number="form.phone"
            maxlength="11"
            placeholder="请输入手机号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="sendBtn" type="primary" @click="sendCode"
            >发送验证码</el-button
          >
        </el-form-item>
      </div>
      <div v-show="progress == '2'" class="first" style="height: 200px">
        <el-form-item prop="word">
          <el-input v-model="form.word" placeholder="请输入验证码"></el-input>
        </el-form-item>
        <el-form-item prop="pass">
          <el-input
            v-model="form.pass"
            type="password"
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>
        <el-form-item prop="checkPass">
          <el-input
            v-model="form.checkPass"
            type="password"
            placeholder="请再次输入密码"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="sendBtn" @click="register"
            >注 册</el-button
          >
        </el-form-item>
      </div>
      <div v-show="progress == '3'">
        <el-result icon="success" title="提示" subTitle="注册成功!">
        </el-result>
      </div>
    </el-form>
    <el-dialog
      title="请输入图形验证码"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="300px"
    >
      <div style="display: flex; align-items: center">
        <el-input
          prefix-icon="el-icon-picture-outline "
          v-model.trim.number="smscode"
          placeholder="验证码"
          style="width: calc(100% -120px)"
        ></el-input>

        <div style="width: 120px; height: 40px" @click="getYzm">
          <Yzm :contentWidth="100" :identifyCode="key"></Yzm>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="getCode">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Yzm from "../Register/list.vue";
const regExp = new RegExp("^1[3456789]\\d{9}$"); // 手机号正则
export default {
  components: {
    Yzm,
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var checkPhone = (rule, value, callback) => {
      console.log(value);
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else if (!regExp.test(value)) {
        callback(new Error("请输入正确的手机号!"));
      } else {
        callback();
      }
    };
    return {
      progress: "3",
      checked: true,
      dialogVisible: false,
      key: "",
      smscode: "",
      form: {},
      rules: {
        phone: [
          {
            required: true,
            validator: checkPhone,
            trigger: "blur",
          },
        ],
        word: [
          { required: true, message: "请输入手机验证码", trigger: "blur" },
        ],
        pass: [{ required: true, message: "请输入密码", trigger: "blur" }],
        checkPass: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.getYzm();
  },
  methods: {
    getYzm() {
      var str = "qwertyupkjhgfdsazxcvbnm23456789QWERTYUPKJHGFDSAZXCVBNM";
      var Yzm = "";
      for (var i = 0; i <= 3; i++) {
        var item = parseInt(Math.random() * (str.length - 1 + 0 + 1) - 0);
        Yzm += str[item];
      }
      this.key = Yzm;
    },
    sendCode() {
      this.$refs.form.validateField("phone", (valid) => {
        if (valid) return;
        this.dialogVisible = true;
      });
    },
    getCode() {
      if (this.smscode.toUpperCase() === this.key.toUpperCase()) {
        this.dialogVisible = false;
        this.$http
          .post("/Base_Manage/Base_User/SaveRegister", {
            Phone: this.form.phone,
          })
          .then((res) => {
            if (res.Success) {
              this.progress = "2";
              this.$message.success("验证码已经发送至您的手机,请注意查收");
            } else {
              this.$message.error(res.Msg);
            }
          });
      } else {
        this.$message.error("请输入正确的图形验证码");
      }
    },
    register() {
      this.$refs.form.validate((valid) => {
        console.log(valid);
        if (!valid) return;

        this.$http
          .post("/Base_Manage/Base_User/SaveUserModel", {
            UserPhone: this.form.phone,
            Password: this.form.checkPass,
            SmsCode: this.form.word,
          })
          .then((res) => {
            if (res.Success) {
              this.$message.success(res.Msg);
            } else {
              this.$message.error(res.Msg);
            }
          });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.userRegister {
  //   background-image: url("../../assets/PublicAccountBG.png");
  //   background-size: cover;
  //   background-repeat: no-repeat;
  height: 100%;
  box-sizing: border-box;
  padding: 20px;
  h2 {
    font-weight: bold;
  }
  .first {
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .checkBox {
      display: flex;
      align-items: flex-end;
      zoom: 1.2;
    }
    .sendBtn {
      width: 100%;
    }
  }
}
</style>